<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <h4>Lesson: {{ GET_ONE_CONTENT.title }}</h4>
      </b-col>
    </b-row>
    <h4
      v-if="!GET_ONE_CONTENT.lesson_relation.length"
      class="text-center mt-5"
    >
      {{ $t('general.no_info') }}
    </h4>
    <b-tabs v-else>
      <b-tab
        v-for="(item, index) in GET_ONE_CONTENT.lesson_relation"
        :key="index"
        :title="item.title"
        lazy
      >

        <custom-dragbar
          v-if="item.description && item.contents.length"
        >
          <template v-slot:left-side>
            <b-card
              class="h-100 mr-sm-1 mr-0"
              :class="item.options? 'pt-4' : ''"
            >
              <div class="overflow-auto">

                <template v-if="item.options">
                  <div
                    v-if="item.options.audio_track"
                    class="audio-container"
                  >
                    <vue-plyr :options="options">
                      <audio
                        controls
                        crossorigin
                        playsinline
                        autoplay
                      >
                        <source
                          :src="item.options.audio_track"
                        >
                        Your browser does not support the audio element.
                      </audio>
                    </vue-plyr>
                  </div>
                </template>
                <div
                  class="ck-content"
                  v-html="item.description"
                />
              </div>
            </b-card>
          </template>
          <template v-slot:right-side>
            <b-card
              class="h-100  ml-sm-1 ml-0"
              style="overflow: auto;"
            >
              <b-tabs class="h-100">
                <b-tab
                  v-for="contents in item.contents"
                  :key="contents.id"
                  class="h-100 vh-75"
                  lazy
                >
                  <template
                    v-if="contents.file"
                    #title
                  >
                    <feather-icon :icon="contents.data_type[1]" />
                    <span>
                      {{ contents.data_type[0] }}
                    </span>
                  </template>
                  <template
                    v-else-if="contents.url"
                    #title
                  >
                    <feather-icon icon="LinkIcon" />
                    <span>
                      url
                    </span>
                  </template>
                  <all-doc-viewer
                    class="w-100"
                    :src="contents.url? contents.url : contents.file"
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </custom-dragbar>

        <b-card
          v-else
          class="pt-4"
        >
          <template v-if="item.options">
            <div
              v-if="item.options.audio_track"
              class="audio-container"
            >
              <vue-plyr :options="options">
                <audio
                  controls
                  crossorigin
                  playsinline
                  autoplay
                >
                  <source
                    :src="item.options.audio_track"
                  >
                  Your browser does not support the audio element.
                </audio>
              </vue-plyr>
            </div>
          </template>
          <b-tabs>
            <template v-if="item.contents.length">
              <b-tab
                v-for="content in item.contents"
                :key="content.id"
                class="vh-75"
              >
                <template
                  v-if="content.data_type && content.data_type.length"
                  #title
                >
                  <feather-icon :icon="content.data_type[1]" />
                  <span>
                    {{ content.data_type[0] }}
                  </span>
                </template>
                <template
                  v-else-if="content.url"
                  #title
                >
                  <feather-icon icon="LinkIcon" />
                  <span>
                    url
                  </span>
                </template>
                <all-doc-viewer
                  class="w-100"
                  :src="content.url? content.url : content.file"
                />
              </b-tab>
            </template>
            <div
              v-else
              class="ck-content"
              v-html="item.description"
            />
          </b-tabs>
        </b-card>
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="success"
        @click="$router.go(-1)"
      >
        {{ $t('tests.complete') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BCard,
  BTab,
  BTabs,
  BRow,
  BButton,
} from 'bootstrap-vue'
import VuePlyr from 'vue-plyr'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import customDragbar from '@/components/customDragbar.vue'
import AllDocViewer from '@/components/AllDocViewer.vue'

export default {
  components: {
    customDragbar,
    BCard,
    BTab,
    BTabs,
    AllDocViewer,
    BRow,
    BButton,
    BCol,
    VuePlyr,
  },
  data() {
    return {
      options: {
        seekTime: 2,
      },
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_LESSON_CONTENT', 'GET_LESSON', 'GET_ONE_CONTENT']),
  },
  beforeDestroy() {
    if (this.$can('manage', 'student')) {
      this.UPDATE_LESSON_VIEW_HISTORY({
        id: sessionStorage.getItem('lesson_history_id'),
        end_time: new Date().toISOString(),
      })
    }
    sessionStorage.removeItem('lesson_history_id')
  },
  created() {
    this.FETCH_LESSON(this.$route.params.themeId)
      .then(() => {
        this.updateBreadcrumb()
      })
    if (!sessionStorage.getItem('lesson_history_id') && this.$can('manage', 'student')) {
      this.CREATE_LESSON_VIEW_HISTORY({
        lesson: this.$route.params.themeId,
      }).then(res => {
        console.log(res)
        sessionStorage.setItem('lesson_history_id', res.data.id)
      })
    }
    // this.FETCH_ONE_CONTENT(this.$route.params.themeId)
  },
  methods: {
    ...mapActions('courses', ['FETCH_LESSON', 'FETCH_ONE_CONTENT', 'CREATE_LESSON_VIEW_HISTORY', 'UPDATE_LESSON_VIEW_HISTORY']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    updateHistory() {

    },
    updateBreadcrumb() {
      const [first, second, three] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      // second.to = `/course/${this.$route.params.id}`
      // second.active = false
      three.text = this.GET_ONE_CONTENT.title
      this.UPDATE_BREADCRUMB([first, second, three])
    },
  },
}
</script>

<style lang="scss">
.vh-75 {
  height: 75vh!important;
}
.audio-container .plyr__controls .plyr__menu button {
  display: none;
}
.audio-container{
  position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
}

@media (max-width: 500px) {
    .ress__jus{
      justify-content: space-between!important;
      padding-right: 10px;
      padding-left: 10px;
    }
    .unselectable{
      display: block !important;
    }

    .unselectable div {
      width: 100%!important;
    }
    #test-footer{
      position: fixed!important;
      z-index: 9999;
    }

    .res__btn{
        display: block!important;

        .circle_buttons {
          width: 100%!important;
          justify-content: center;
          padding-bottom: 20px;
        }
    }
  }
</style>
